<template>
  <div>
    <div id="PaymentModule" class="ReferencePaymentMain">
      <ReferenceNavBar />
      <br />
      <br />
      <br />
      <div class="text-none mr-3 Payment">Оплата заказа № {{ orderNumber }}</div>
    </div>
  </div>
</template>

<script>
import ReferenceNavBar from '@/components/reference/ReferenceNavBar';
export default {
  components: {
    ReferenceNavBar
  },
  computed: {
    returnUrl() {
      return window.location.protocol + '//' + window.location.host + '/complete?token=' + this.$route.query.token;
    },
    orderNumber() {
      return this.$route.query.order;
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  },
  mounted() {
    let attachScript = document.createElement('script');
    attachScript.onload = () => {
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: this.$route.query.token,
        error_callback: function(err) {
          console.log(err);
        },
        return_url: this.returnUrl
      });
      checkout.render('PaymentModule');
    };
    attachScript.setAttribute('src', 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js');
    document.head.appendChild(attachScript);
  }
};
</script>
<style>
.ReferencePaymentMain {
  font-weight: 400;
  font-family: RussianRail;
}
.Payment {
  text-align: center;
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
